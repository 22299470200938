import React from "react";
import { Link } from "react-router-dom";
import { configService } from 'services/config.service';
import { authenticationService } from 'services/authentication.service';
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Alert,
    Modal,
    Spinner,
    Breadcrumb, BreadcrumbItem,UncontrolledTooltip
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {auditService} from "../services/audit.service";

class Module extends React.Component {

    constructor() {
        super();
        this.state = {
            wsConfigs : [],
            apisConfigs : [],
            searchResultsIsEmpty: false,
            errorMessage: '',
            input: ''
        }
    }
    onSelectModule = (module) =>{
        auditService.trace("Select ConnectAPI Module", "Select page", new Date(),authenticationService.getCurrentUser(),module)
            .then(r  => {})
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
        if (!authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
        this.onConfigLoad();
    }
    toggleFailModal =  (state,msg) => {
        this.setState({
            [state]: !this.state[state],
            errorMessage : msg
        });
        if(state === "close"){
            authenticationService.logout();
            window.location.replace("/login");
        }
    };

    handleChange=(e)=> {
        this.setState({ input: e.target.value });
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchApi();
        }
    }

    searchApi=() =>{
        if(this.state.input === ""){
            this.setState({
                searchResultsIsEmpty: false,
                apisConfigs: []
            });
        }else{
            document.getElementById('module-spinner2').style.display = 'block';
            document.getElementById('module-spinner2').classList.add("module-spinner-search");
            configService.getModulesBySolutionIdAndApi(this.state.input,this.props.match.params.id)
                .then(response => {
                    if(response != null && response.length ==0){
                        this.setState({
                            searchResultsIsEmpty: true,
                            apisConfigs: []
                        });
                    }else{
                        let values = response;
                        this.setState({
                            apisConfigs: values,
                            searchResultsIsEmpty: false
                        });
                    }
                    var el = document.getElementById('module-spinner2');
                    if(el)
                        el.style.display = 'none';
                }, (error) => {
                    var el = document.getElementById('module-spinner2');
                    if(el)
                        el.style.display = 'none';
                    console.log("ERROR : "+JSON.stringify(error))
                    if(error && error.status === 401){
                        this.toggleFailModal("failureModal","Your session is expired please login again.");
                    }else{
                        this.toggleFailModal("failureModal","Internal error please try again later.");
                    }
                });
        }
    }
    onConfigLoad = () => {
        sessionStorage.setItem("selected-module",window.location.pathname)
        document.getElementById('module-spinner').style.display = 'block';
        document.getElementById('module-spinner').classList.add("module-spinner");
        configService.getModulesBySolutionId(this.props.match.params.id)
            .then(response => {
                let values = response;
                this.setState({
                    wsConfigs: values
                });
                var el = document.getElementById('module-spinner');
                if(el)
                    el.style.display = 'none';
            }, (error) => {
                var el = document.getElementById('module-spinner');
                if(el)
                    el.style.display = 'none';
                console.log("ERROR : "+JSON.stringify(error))
                if(error && error.status === 401){
                    this.toggleFailModal("failureModal","Your session is expired please login again.");
                }else{
                    this.toggleFailModal("failureModal","Internal error please try again later.");
                }
            });
    }
    renderSwitch(param) {
        switch(param.toUpperCase()) {
            case 'CUSTOMER':
                return   <img className="module-card-icon"  src={require("assets/img/cards/customer-mgmt.png")} />;
            case 'BOARDING':
                return   <img className="module-card-icon"  src={require("assets/img/cards/boarding.png")} />;
            case "PAYMENT INSTRUMENT":
                return   <img className="module-card-icon"  src={require("assets/img/cards/cards.png")} />;
            case "LOAN":
                return   <img className="module-card-icon"  src={require("assets/img/cards/loan.png")} />;
            case "FINANCIAL ACCOUNT":
                return   <img className="module-card-icon"  src={require("assets/img/cards/finance_account.png")} />;
            case "LOYALTY":
                return   <img className="module-card-icon"  src={require("assets/img/cards/loyalty.png")} />;
            case "TOKENIZATION":
                return   <img className="module-card-icon"  src={require("assets/img/cards/tokenization.png")} />;
            case "SWITCH API":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m3.png")} />;
            case "SWITCH OPERATION":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m33.png")} />;
            case "SWITCH CHANNEL":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m33.png")} />;
            case "ATM API":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m5.png")} />;
            case "XPOS API":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m4.png")} />;
            case "MERCHANT":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m2.png")} />;
            case "SWITCH":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m3.png")} />;
            case "CORPORATE":
                return   <img className="module-card-icon"  src={require("assets/img/cards/corporate.png")} />;
            case "CONTRACT":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m9.png")} />;
            case "TRANSACTION":
                return   <img className="module-card-icon"  src={require("assets/img/cards/t1.png")} />;
            case "DEVICE":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m4.png")} />;
            case "ETHOCA":
                return   <img className="module-card-icon"  src={require("assets/img/cards/ethoca.png")} />;
            case "FLD":
                return   <img className="module-card-icon"  src={require("assets/img/cards/fld.png")} />;

            case "TERMINAL":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m4.png")} />;
            case "ACQUIRER":
                return   <img className="module-card-icon"  src={require("assets/img/cards/m2.png")} />;
            case "VIS":
                return   <img className="module-card-icon"  src={require("../assets/img/cards/vis.png")} />;
            case "VADS":
                return   <img className="module-card-icon"  src={require("../assets/img/cards/VADSLogo.png")} />;
            default:
                return   <img className="module-card-icon"  src={require("assets/img/cards/m1.png")} />;

        }
    }
    render() {
        const baseUrl = "assets/img/cards/";
        return (
            <>
                <AppNavbar />
                {/* on failure model */}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.failureModal}
                    toggle={() => this.toggleFailModal("failureModal")}
                >
                    <div className="modal-header">
                        <h5 className="modal-title modal-title-error" id="exampleModalLabel">
                            Sorry!
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleFailModal("close")}
                        >
                            <span aria-hidden={true}>X</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/** <p className="modal-message-error"></p> */}
                        <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
                        </Alert>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            className="btn-close-modal"
                            onClick={() => this.toggleFailModal("close")}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                <main className="profile-page version-page" ref="main">
                    <section className="section-profile-cover section-shaped my-0 main-version-bg">
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb className="breadcrumbs-cust">
                                <BreadcrumbItem   active>Home</BreadcrumbItem>
                            </Breadcrumb>
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                                <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                                <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-version"))}>Releases</Link></BreadcrumbItem>
                                <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-solution"))}>Solutions</Link></BreadcrumbItem>
                                <BreadcrumbItem active>Modules</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </section>
                    <section className="section main-module-sub-bg">
                        <Container>
                            <Card className="card-profile mt--300">
                                <Row className="justify-content-center">
                                    <Row className="justify-content-center">
                                        <Col lg="12">
                                            <div >
                                                <h3 className="module-title-wrap">{this.props.match.params.name} Modules</h3>
                                                <p className="pg-version-sub-title module-list">
                                                    {sessionStorage.getItem("selected-solution-description")}
                                                </p>
                                                <div className="badge-container">
                                                    <Spinner animation="border" className="module-spinner-search" id="module-spinner2"/>
                                                    {this.state.apisConfigs.map(config =>
                                                        <Badge id={config.id} to={"/"+config.link+"?api="+config.name} tag={Link} color="warning search-result-badge" pill>
                                                            {config.name}
                                                        </Badge>
                                                    )}
                                                    {
                                                        this.state.searchResultsIsEmpty == true &&
                                                        <span className="search-no-results">No result found.</span>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col lg="12">
                                        <Row className="row-grid row-module">
                                            <Spinner animation="border" className="module-spinner" id="module-spinner"/>
                                            {this.state.wsConfigs.map(config => (
                                                <Col lg="4"  key={config.id}>
                                                    <Card  onClick={()=>this.onSelectModule(config.name)} id="tooltip" className="card-lift--hover shadow border-0 card-module" to={"/swagger-ui"+config.wsYamlUrl} tag={Link}>
                                                        <CardBody className="py-5 py-style"  >
                                                            <div className="icon icon-shape  rounded-circle mb-4">
                                                                {this.renderSwitch(config.name)}
                                                                {config.latestVersion ? (
                                                                    <Badge  color="primary" pill className="mr-1 module-card-name module-badge v4-latest-version">
                                                                        {config.name}
                                                                    </Badge>
                                                                ):(
                                                                    <Badge  color="primary" pill className="mr-1 module-card-name module-badge">
                                                                        {config.name}
                                                                    </Badge>
                                                                )}

                                                            </div>

                                                            <div>

                                                            </div>
                                                            {/*   <Button
                                         disabled={!config.enabled}
                                       className="mt-4 btn-select-module btn-card-select-module "
                                       color="primary"
                                       to={"/swagger-ui"+config.wsYamlUrl} tag={Link}
                                      >
                                      Explore
                                     </Button> */}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                            { this.state.wsConfigs.length  === 0 &&
                                                <div>
                                                    <p className="no-available-module">No module available in this solution.</p>
                                                </div>
                                            }

                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Container>

                    </section>

                </main>
                <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
                <SimpleFooter />
            </>
        );
    }
}

export default Module;
